/**
 * Class for custom select fields
 */
var Masonry = new function () {
  /**
   * Internal context
   */
  var _this = this

  /**
   * Class elements
   */
  this.selector = '.gallery-masonry'
  this.elems = document.querySelector('.gallery-masonry')

  /**
   * Initialize the class
   */
  this.init = function () {
    if (_this.elems) {
      document.querySelectorAll('img[data-src]')
        .forEach(function (img) {
          img.setAttribute('src', img.getAttribute('data-src'))
          img.onload = function () {
            img.removeAttribute('data-src')
          }
        })
    }

    if (_this.elems && 0 === 1) {
      var masonry = new Macy({
        container: _this.selector,
        columns: 2,
        margin: 10,
        trueOrder: false,
        wairForImages: false,
        breakAt: {840: 1}
      })
    }
  }
}()
